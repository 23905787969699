export enum AllocationColorBarTest {
    AllocationColorBar = 'allocation-color-bar-test-id',
}

export enum BadgeTest {
    Badge = 'badge-test-id',
}

export enum ButtonGroupTest {
    TOGGLE = 'button-group-toggle-test-id',
    LABEL = 'button-group-label-test-id',
}

export enum CardDetailsTest {
    CARD = 'card-details-card-test-id',
    ITEMS = 'card-details-items-test-id',
    CONTENT = 'card-details-content-test-id',
    HEADER = 'card-details-header-test-id',
    ACTION = 'card-details-action-test-id',
}

export enum CardColumnsTest {
    COLUMNS = 'card-columns-test-id',
    ITEMS = 'card-columns-items-test-id',
}

export enum CardInfoTest {
    INFO = 'card-info-test-id',
    ITEMS = 'card-info-items-test-id',
    ICON = 'card-info-icon-test-id',
}

export enum CheckboxTest {
    Checkbox = 'checkbox-test-id',
}

export enum ChipTest {
    Chip = 'chip-test-id',
    StartIcon = 'chip-start-icon-test-id',
    EndIcon = 'chip-end-icon-test-id',
}

export enum DrawerDetailsTest {
    DRAWER = 'drawer-details-drawer-test-id',
}

export enum FeaturesCardsTest {
    Cost = 'features-cards-cost',
    CumulativePayment = 'features-cards-payment',
    EffectiveDate = 'features-cards-effective-date',
    ExpirationDate = 'features-cards-expiration-date',
}
export enum RidersCardsTest {
    ClaimProcessed = 'riders-cards-claim-processed',
    ClaimProcessedDate = 'riders-cards-claim-processed-date',
    ClaimStatus = 'riders-cards-claim-status',
    EffectiveDate = 'riders-cards-effective-date',
    ExpirationDate = 'riders-cards-expiration-date',
    Insured = 'riders-cards-insured',
}

export enum FieldDateSelectTest {
    Container = 'field-date-select-container-test-id',
}

export enum FieldTest {
    Container = 'field-container-test-id',
    Input = 'field-input-test-id',
    Cancel = 'field-input-cancel-id',
}

export enum FilterButtonTest {
    Container = 'filter-button-container-test-id',
}

export enum LanguageSwitcherTest {
    BOX = 'language-switcher-box-test-id',
    DEFAULT = 'language-switcher-default-test-id',
    ENGLISH = 'language-switcher-en-test-id',
    FRENCH = 'language-switcher-fr-test-id',
}

export enum ModalTest {
    HEAD = 'modal-head-test-id',
    HEAD_CHILDREN = 'modal-head-children-test-id',
    BODY = 'modal-body-test-id',
    BODY_CHILDREN = 'modal-body-children-test-id',
    FRAME = 'modal-frame-test-id',
    FRAME_CHILDREN = 'modal-frame-children-test-id',
    FRAME_CLOSE = 'modal-frame-close-test-id',
}

export enum NestedNavDrawerTest {
    NESTED_NAV_PARENT_ITEM = 'nested-nav-parent-item-test-id',
    START_ICON = 'nested-nav-start-icon-test-id',
    END_ICON = 'nested-nav-end-icon-test-id',
}

export enum ProgressBarStepsTest {
    ClickContainer = 'click-container',
    ClickWrapper = 'click-wrapper',
    ContentContainer = 'content-container',
    StepText = 'step-text',
    ProgressBarSteps = 'progress-bar-steps',
    StepsContainer = 'steps-container',
}

export enum PopoverTest {
    Popover = 'popover-popover-test-id',
    Body = 'popover-body-test-id',
    Title = 'popover-title-test-id',
    Cancel = 'popover-cancel-icon-test-id',
    Content = 'popover-content-test-id',
}

export enum TooltipTest {
    Tooltip = 'tooltip-test-id',
    Body = 'tooltip-body-test-id',
}

export enum ProgressBarTest {
    PROGRESSBAR = 'progress-bar-test-id',
    TOTAL = 'progress-bar-total-test-id',
    COMPAREVALUE = 'progress-bar-compare-value-test-id',
    LABEL = 'progress-bar-label-test-id',
}

export enum PremiumCardTest {
    PREMIUMCARD = 'premium-card-test-id',
    FOOTER = 'premium-card-footer-test-id',
}

export enum RadioTest {
    Radio = 'radio-test-id',
}

export enum CaseOverviewTest {
    CASE_OVERVIEW = 'case-overview-test-id',
}

export enum StepStatusTest {
    STEP_STATUS = 'step-status-test-id',
    STEP_INFO = 'step-info-test-id',
}

export enum PolicyExtrasTest {
    FIELD = 'policy-extras-container-field-test-id',
    CONTAINER = 'policy-extras-container-test-id',
    SUBHEADER = 'policy-extras-subheader-test-id',
}

export enum ResponsiveGridTest {
    CONTAINER = 'responsive-grid-test-id',
}

export enum LoansTest {
    START_LOAN_LINK = 'start-loan-link',
    START_LOAN_LINK_DISABLED = 'start-loan-link-disabled',
}
